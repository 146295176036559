<template>
    <b-modal :visible="isModalActive" :title="$t('User Info')" ok-title="Accept"
        @change="(val) => $emit('update:is-modal-active', val)">
        <b-overlay :show="dataLoading" rounded="sm">
            <label>{{ $t('User Info') }}</label>
            <b-list-group class="mb-2">
                <b-list-group-item>
                    {{ this.$t('Username') }} : {{ username }}
                </b-list-group-item>
                <b-list-group-item>
                    {{ this.$t('FullName') }} : {{ firstName }} {{ lastName }}
                </b-list-group-item>
                <b-list-group-item>
                    {{ this.$t('Date Of Birth') }} : {{ dateOfBirth | formatDate }}
                </b-list-group-item>
                <b-list-group-item>
                    {{ this.$t('Gender') }} : {{ $t(gender) }}
                </b-list-group-item>
            </b-list-group>
            <div v-if="address.length > 0">
                <label>{{ $t('Address') }}</label>
                <b-list-group class="mb-2">
                    <b-list-group-item v-for="item, index in address" :key="index">
                        <b-card-text class="mb-0">
                            {{ $t('Phone Number') }} : {{ item.tel }},
                            {{ $t('Address') }} : {{ item.address }},
                            {{ $t('Subdistrict') }} : {{ item.subdistrictId != null ?
                                showFromCurrentLanguage(item.subdistrictId.name) : '' }},
                            {{ $t('District') }} : {{ item.districtId != null ?
                                showFromCurrentLanguage(item.districtId.name) : '' }},
                            {{ $t('Province') }} : {{ item.provinceId != null ?
                                showFromCurrentLanguage(item.provinceId.name) : '' }},
                            {{ $t('ZipCode') }} : {{ item.zipCode }}
                        </b-card-text>
                    </b-list-group-item>
                </b-list-group>
            </div>

        </b-overlay>

        <template #modal-footer>
            <div class="w-100">
                <p class="float-left mb-0" />
                <div class="float-right">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class=""
                        @click="$emit('update:is-modal-active', false)">
                        {{ $t('Close') }}
                    </b-button>
                </div>

            </div>
        </template>

    </b-modal>
</template>
  
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
    BModal,
    BRow,
    BCol,
    BCardText,
    BFormCheckbox,
    BButton,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
        BModal,
        // eslint-disable-next-line vue/no-unused-components
        BRow,
        // eslint-disable-next-line vue/no-unused-components
        BCol,
        // eslint-disable-next-line vue/no-unused-components
        BCardText,
        // eslint-disable-next-line vue/no-unused-components
        BFormCheckbox,
        // eslint-disable-next-line vue/no-unused-components
        BButton,
        BInputGroup,
        BInputGroupAppend,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BOverlay,
    },
    directives: {
        Ripple,
    },
    mixins: [togglePasswordVisibility],
    model: {
        prop: 'isModalActive',
        event: 'update:is-modal-active',
    },
    props: {
        storeModuleName: {
            type: String,
            required: true,
        },
        isModalActive: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            default: () => { },
        },
        show: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            dataId: null,
            username: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            gender: '',
            address: [],
            dataLoading: false,
            required,
        }
    },
    computed: {

    },
    watch: {
        isModalActive(val) {
            if (val) {
                if (Object.entries(this.data).length === 0) {
                    this.initValues()
                } else {
                    const {
                        _id,
                        userId,
                        firstName,
                        lastName,
                        dateOfBirth,
                        gender,
                        address
                    } = this.data
                    this.dataId = _id
                    this.username = userId.username
                    this.firstName = firstName
                    this.lastName = lastName
                    this.dateOfBirth = dateOfBirth
                    this.gender = gender
                    this.address = address
                }
            }
        },
    },
    methods: {
        showFromCurrentLanguage(data) {
            const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
            if (indexLang > -1) {
                return data[indexLang].value
            }
            return ''
        },
        subStringOrderId(data) {
            if (data) {
                return data.substr(16, data.length)

            }
            return ''
        },
        initValues() {
            this.dataId = null
            this.passwd = ''
            this.password = ''
        },
        submitData() {
            this.$refs.loginForm.validate().then(success => {
                if (success) {
                    this.$bvModal
                        .msgBoxConfirm(`${this.$t('Do you want to change')}?`, {
                            title: this.$t('Please Confirm'),
                            cancelVariant: 'outline-secondary',
                            okVariant: 'success',
                            okTitle: this.$t('Yes'),
                            cancelTitle: this.$t('No'),
                            centered: true,
                        })
                        // eslint-disable-next-line no-unused-vars
                        .then(value => {
                            if (value) {
                                this.dataLoading = true
                                store
                                    .dispatch(`${this.storeModuleName}/updatePassword`, { id: this.dataId, passwd: this.passwd, password: this.password })
                                    // eslint-disable-next-line no-unused-vars
                                    .then(result => {
                                        this.dataLoading = false
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'bottom-right',
                                            props: {
                                                title: this.$t('Success'),
                                                icon: 'CheckIcon',
                                                variant: 'success',
                                                text: this.$t(result.data.message),
                                            },
                                        })
                                        this.initValues()
                                        this.$emit('update:is-modal-active', false)
                                    }).catch(error => {
                                        this.dataLoading = false

                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'bottom-right',
                                            props: {
                                                title: this.$t('Error'),
                                                icon: 'ErrorIcon',
                                                variant: 'danger',
                                                text: this.$t(error.response.data.message),
                                            },
                                        })
                                        // console.log('fetchUsers Error : ', error)
                                        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                                    })
                            }

                            //  else {
                            //   this.showToast(
                            //     'warning',
                            //     'top-right',
                            //     `${this.$t('DELETE_CANCEL')}!`,
                            //     'AlertCircleIcon',
                            //     this.$t('DELETE_CANCEL'),
                            //   )
                            // }
                        })
                }
            })
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
  